import fetchClient from "../../config/client";



const getClientProjects = async (q_params) => {
  let projects = await fetchClient
    .get("/client/projects.json", { params: q_params })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return projects;
};
export const ClientProjectsActions = {
	getClientProjects,

};

export default ClientProjectsActions;
