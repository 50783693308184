import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: '',
  data: {},
  currently_selected: [],
  removed_service_items: [],
  current_doc_collection: {
    id: -1,
    service_request_id: -1,
    service_request_item_ids: [],
    service_request_event_id: -1,
    new_service_items: [],
    results_collected_service_items: [],
    attachments: [],
  },
};

export const document_collection_slice = createSlice({
  name: 'document_collection',
  initialState,

  reducers: {
    setBase: (state, action) => {
      return {
        ...state,
        current_doc_collection: {
          ...state.current_doc_collection,
          id: action.payload.id,
          service_request_id: action.payload.service_request_id,
          service_request_event_id: action.payload.service_request_event_id,
          new_service_items: action.payload.new_service_items,
          results_collected_service_items:
            action.payload.results_collected_service_items,
          attachments: action.payload.attachments || [],
        },
      };
    },

    resetAll: (state, action) => {
      state = initialState;
      return state;
    },

    updateAttachment: (state, action) => {
      state.current_doc_collection.attachments = [];

      action.payload.attachments.forEach((single_attachment) => {
        let prepare_file_obj = {
          id: single_attachment.id,
          file_name: single_attachment.file_name,
          file_url: single_attachment.file_url,
          object_key: single_attachment.object_key,
          in_active: single_attachment.in_active,
          name: single_attachment.name,
          receipt: single_attachment.receipt,
          notes: single_attachment.notes,
        };

        if ('_destroy' in single_attachment) {
          prepare_file_obj._destroy = single_attachment._destroy;
        }

        state.current_doc_collection.attachments.push(prepare_file_obj);
      });

      return state;
    },

    updateItemStatus: (state, action) => {
      if (action.payload.new_status === 'Results Collected') {
        // Move items from "New" to "Results Collected"
        let results_collected_items_temp = [
          ...state.current_doc_collection.results_collected_service_items,
        ];
        let new_items_temp = [
          ...state.current_doc_collection.new_service_items,
        ];
        let currently_selected_temp = [...state.currently_selected];

        state.current_doc_collection.new_service_items.forEach(
          (single_item) => {
            if (state.currently_selected.includes(single_item.id)) {
              single_item.status = 'Results Collected';
              results_collected_items_temp.push(single_item);
            }
          }
        );

        state.current_doc_collection.results_collected_service_items =
          results_collected_items_temp;

        // Now remove items from 'new_service_items'
        state.current_doc_collection.new_service_items = [];
        new_items_temp.forEach((single_item) => {
          if (state.currently_selected.includes(single_item.id)) {
          } else {
            state.current_doc_collection.new_service_items.push(single_item);
          }
        });
        state.current_doc_collection.new_service_items.filter(
          (single_item) => !currently_selected_temp.includes(single_item.id)
        );

        state.currently_selected = [];
      } else if (action.payload.new_status === 'New') {
        // Move items from "Results Collected" to "New"
        let results_collected_items_temp = [
          ...state.current_doc_collection.results_collected_service_items,
        ];
        let new_items_temp = [
          ...state.current_doc_collection.new_service_items,
        ];
        let currently_selected_temp = [...state.currently_selected];
        let temp_removed_service_items = [...state.removed_service_items];

        state.current_doc_collection.results_collected_service_items.forEach(
          (single_item) => {
            if (state.currently_selected.includes(single_item.id)) {
              single_item.status = 'New';
              new_items_temp.push(single_item);
              temp_removed_service_items.push(single_item);
            }
          }
        );

        state.current_doc_collection.new_service_items = new_items_temp;
        state.removed_service_items = temp_removed_service_items;

        // Now remove items from 'results_collected_service_items'
        state.current_doc_collection.results_collected_service_items = [];
        results_collected_items_temp.forEach((single_item) => {
          if (state.currently_selected.includes(single_item.id)) {
          } else {
            state.current_doc_collection.results_collected_service_items.push(
              single_item
            );
          }
        });
        state.current_doc_collection.results_collected_service_items.filter(
          (single_item) => !currently_selected_temp.includes(single_item.id)
        );

        state.currently_selected = [];
      }

      return state;
    },
    addItem: (state, action) => {
      return {
        ...state,
        currently_selected: [...state.currently_selected, action.payload.id],
      };
    },
    removeItem: (state, action) => {
      let currently_selected_temp = [...state.currently_selected];
      currently_selected_temp = currently_selected_temp.filter(
        (item) => item !== action.payload.id
      );

      return {
        ...state,
        currently_selected: currently_selected_temp,
      };
    },
    resetClickedItems: (state, action) => {
      return {
        ...state,
        currently_selected: [],
      };
    },
  },
});

export const { reducer, actions } = document_collection_slice;
export default reducer;
