import React from 'react';
import { useState, useEffect } from 'react';
import ServiceRequestForm from '../service_request/service_request_form';
import ServiceRequestActions from '../../../actions/service_requests';
import Pagination from '../../pagination/Pagination';
import ServiceRequestsTableList from '../../helpers/service_requests_table_list';
// UseUpdateEffect util
import useUpdateEffect from '../../../utils/useUpdateEffect';

const ServiceRequestsList = ({ requestStatus }) => {
  const [service_requests, setServiceRequests] = useState([]);
  const [order_field, setOrderField] = useState('new_from_customer');
  const [sort, setSort] = useState('desc');
  const [showFormModal, setShowFormModal] = useState(false);
  const [current_page, setCurrentPage] = useState(1);
  const [page_count, setPageCount] = useState(1);
  const [clickforcePage, setClickForce] = useState(null);
  //  "Search field"
  // for search field all states
  const [displayInputLabel, setDisplayInputLabel] = useState('');
  const [searchField, setSearchField] = useState('');
  // search current page
  const [searchPage, setSearchPage] = useState(1);
  // sorting
  const [searchSort, setSearchSort] = useState('desc');
  const [searchOrderField, setSearchOrderField] = useState('new_from_customer');
  const [inputEvent, setInputEvent] = useState(null);

  function handlePageClick({ selected: selectedPage }) {
    if (searchField !== '') {
      setSearchPage(selectedPage + 1);
    } else {
      setCurrentPage(selectedPage + 1);
    }
    setClickForce(selectedPage);
  }

  const searchServiceRequestOnPage = async () => {
    let response = await ServiceRequestActions.getList({
      page: searchPage,
      search: {
        g: {
          0: {
            m: 'or',
            project_name_cont: searchField,
            customer_name_cont: searchField,
            patient_full_name_cont: searchField,
            standard_name_cont: searchField,
          },
          1: {
            status_eq: requestStatus,
          },
        },
      },
    });
    setServiceRequests(response.data.service_requests);
  };

  const fetchServiceRequests = async () => {
    let response = await ServiceRequestActions.getList({
      page: current_page,
      search: {
        status_eq: requestStatus,
        s: `${order_field} ${sort}`,
      },
    });
    setServiceRequests(response.data.service_requests);
    setPageCount(response.data.pages);
  };

  const searchServiceRequestList = async (event) => {
    event.preventDefault();
    setInputEvent(event);

    setSearchField(event.target.value);
    let response = await ServiceRequestActions.getList({
      search: {
        g: {
          0: {
            m: 'or',
            project_name_cont: event.target.value,
            customer_name_cont: event.target.value,
            patient_full_name_cont: event.target.value,
            standard_name_cont: event.target.value,
          },
          1: {
            status_eq: requestStatus,
          },
        },
        s: `${searchOrderField} ${searchSort}`,
      },
    });
    setServiceRequests(response.data.service_requests);
    setPageCount(response.data.pages);
  };

  const resetSearch = () => {
    setSearchField('');
    setCurrentPage(1);
    fetchServiceRequests();
    setClickForce(0);
  };

  useEffect(() => {
    if (requestStatus == 'active') {
      fetchServiceRequests();
    }
  }, [requestStatus]);

  useUpdateEffect(() => {
    fetchServiceRequests();
  }, [sort, order_field, current_page]);

  useUpdateEffect(() => {
    searchServiceRequestOnPage();
  }, [searchPage]);

  useEffect(() => {
    inputEvent && searchServiceRequestList(inputEvent);
  }, [searchSort, searchOrderField, inputEvent]);

  const ShowForm = () => {
    if (showFormModal) {
      return (
        <ServiceRequestForm
          showFormModal={showFormModal}
          setShowFormModal={setShowFormModal}
          fetchServiceRequests={fetchServiceRequests}
        />
      );
    }

    return <></>;
  };

  const handleSort = (sort, order_field) => {
    if (searchField !== '') {
      setSearchOrderField(order_field);
      setSearchSort(sort);
    } else {
      setOrderField(order_field);
      setSort(sort);
    }
  };

  const updateNoteHandler = async (id, noteValue) => {
    let data = {
      service_request: {
        coordinator_note: noteValue,
      },
    };
    const response = await ServiceRequestActions.updateServiceRequest(id, data);
    if (response.status == 200) {
    }
  };

  return (
    <>
      {requestStatus === 'active' && (
        <>
          {/* <ShowForm /> */}
          {ShowForm()}
          <div className='action-bar'>
            <section id='contact-form' className='contact-form'>
              <div className='form-block-2'>
                <div className='contact-form-grid'>
                  <div className='filedset  mt-0 flex flex-direction'>
                    <label
                      htmlFor='First-Name'
                      id='contact-first-name'
                      className='modal-label'
                    >
                      &nbsp;
                    </label>
                    <div className='flex'>
                      <input
                        style={{ width: '40%' }}
                        className='modal-input no-mb w-input'
                        type='text'
                        placeholder='Search'
                        value={searchField}
                        // onChange={(event) => searchServiceRequestList(event)}
                        onChange={searchServiceRequestList}
                      />
                      <input
                        onClick={resetSearch}
                        value='Reset'
                        type='submit'
                        data-wait='Please wait...'
                        className='modal-btn mr-t reset w-button'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='action-panel-right'>
                <a
                  href='#'
                  onClick={(event) => {
                    event.preventDefault();
                    setShowFormModal(true);
                  }}
                  className='add-btn'
                >
                  <span className='ico-add'></span>New
                </a>
              </div>
            </section>
          </div>

          <ServiceRequestsTableList
            serviceRequests={service_requests}
            change_handle_sort={handleSort}
            updated_note={updateNoteHandler}
          />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={page_count}
            clickforcePage={clickforcePage}
          />
        </>
      )}
    </>
  );
};
export default ServiceRequestsList;
