import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceRequestItems } from '../../actions/service_request_items';
// service request items
const { getList, updateAllItemsStatus } = ServiceRequestItems;

const initialState = {
  loading: 'idle',
  error: '',
  data: null,
  clicked_item: null || [],
};

export const fetch_task_list = createAsyncThunk('service_request/task_list', async (id, thunkAPI) => {
  try {
    const param = {
      search: {
        service_request_id_eq: id,
      },
    };
    const response = await getList(param);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const update_task_list_status = createAsyncThunk('service_request/update_task_list', async (data, thunkAPI) => {
  try {
    const response = await updateAllItemsStatus(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const task_slice = createSlice({
  name: 'service_request_task_list',
  initialState,

  reducers: {
    clicked_item: (state, action) => {
      return {
        ...state,
        clicked_item: [...state.clicked_item, action.payload],
      };
    },

    remove_item: (state, action) => {
      const data = state.clicked_item.filter((item) => item.id !== action.payload.id);

      return {
        ...state,
        clicked_item: data,
      };
    },

    remove_all_items: (state) => {
      return {
        ...state,
        clicked_item: [],
      };
    },

    select_all_items: (state, { payload }) => {
      return {
        ...state,
        clicked_item: payload,
      };
    },

    deselect_all_items: (state, { payload }) => {
      return {
        ...state,
        clicked_item: payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetch_task_list.pending, (state) => {
      state.data = [];
      state.loading = 'loading';
    });
    builder.addCase(fetch_task_list.fulfilled, (state, data) => {
      state.data = data.payload;
      state.loading = 'loaded';
    });
    builder.addCase(fetch_task_list.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    // ================
    // update_task_list
    // ================
    builder.addCase(update_task_list_status.pending, (state) => {
      state.data = [];
      state.loading = 'loading';
    });
    builder.addCase(update_task_list_status.fulfilled, (state, data) => {
      state.data = data.payload;
      state.loading = 'loaded';
    });
    builder.addCase(update_task_list_status.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
  },
});

export const { clicked_item, remove_item, deselect_all_items, select_all_items, remove_all_items } = task_slice.actions;

export default task_slice.reducer;
