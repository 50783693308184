import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ handlePageClick, pageCount, clickforcePage }) => {
  return (
    <div className='custom-pagination'>
      <ReactPaginate
        pageCount={pageCount}
        onPageChange={handlePageClick}
        pageClassName='sibling-pagination-tab'
        previousClassName='sibling-pagination-tab '
        nextClassName='sibling-pagination-tab'
        breakLabel='...'
        forcePage={clickforcePage}
        previousLabel={'← Previous'}
        nextLabel={'Next →'}
        activeClassName={'active-pagination-tab'}
        activeLinkClassName={'active-pagination-tab'}
        disabledClassName={'disabled-pagination-tab'}
      />
    </div>
  );
};

export default Pagination;
