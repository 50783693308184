import { configureStore } from "@reduxjs/toolkit";

// APP Reducers List
import ffd_reducer from "./service_request/ffd";
import document_reducer from "./service_request/documents_collection_list";
import task_list from "./service_request/task_list";
import service_request from "./service_request/service_request";
import service_request_items_slice from "./service_request/service_request_items";
import document_collection_slice from "./service_request/document_collection";
import admin_fees_slice from "./service_request/admin_fees";
import email_template_slice from "./service_request/email_template";
// Client
import client_reducer from "./client/client";

export const store = configureStore({
  reducer: {
    service_request_ffd: ffd_reducer,
    service_request_document: document_reducer,
    service_request_task: task_list,
    service_request,
    service_request_items: service_request_items_slice,
    document_collection: document_collection_slice,
    admin_fees: admin_fees_slice,
    email_template: email_template_slice,
  },
});
