import fetchClient from '../config/client';

const getList = async (q_params) => {
  let customers = await fetchClient
    .get('/customers.json', { params: q_params })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return customers;
};

const getProjectsCustomers = async () => {
  let customers = await fetchClient
    .get('/customers_projects.json')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return customers;
};

const getProjects = async (id) => {
  let projects = await fetchClient
    .get(`/customers/${id}/projects`)
    .then(function (response) {
      return response.data.projects;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return projects;
};

const getCustomer = async (id) => {
  let customer = await fetchClient
    .get(`/customers/${id}.json`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return {};
    });
  return customer;
};

const getCustomerWithProjects = async (id) => {
  let customers = await fetchClient
    .get(`/customers/customers_with_projects.json`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return {};
    });
  return customers;
};

const deleteCustomer = async (id) => {
  fetchClient
    .delete(`customers/${id}`)
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

const updateCustomer = async (id, params) => {
  let customer = fetchClient
    .put(`customers/${id}`, params)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
  return customer;
};

const createCustomer = async (params) => {
  let customer = fetchClient
    .post(`customers`, params)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response.data;
    });
  return customer;
};

export const CustomerActions = {
  getList,
  getProjects,
  getProjectsCustomers,
  getCustomerWithProjects,
  getCustomer,
  deleteCustomer,
  updateCustomer,
  createCustomer,
};

export default CustomerActions;
