import { useEffect, useState } from "react";
import PatientActions from '../../../actions/patients';
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
const PatientEdit = ({setEditPatient, editPatient, setShowPatientEdit,showPatientEdit }) =>{
  let patient = editPatient;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const [updatedPatient, setUpdatedPatient] = useState({
    first_name:     patient.first_name,
    middle_name:    patient.middle_name,
    last_name:      patient.last_name,
    phone_number:   patient.phone_number,
    patient_email:  patient.patient_email,
    sex:            patient.sex,
    date_of_birth:  patient.date_of_birth
  })
  const handleInputChange =(event)=>{
    let temp_patient = updatedPatient;
    temp_patient[event.target.name] = event.target.value;
    setUpdatedPatient(temp_patient);
  }
  const handleSubmit = async()=>{
    let res = await PatientActions.updatePatient(patient.id, updatedPatient)
    if(res.status === 200){
      setEditPatient(res.data.patient);
      setShowPatientEdit(false);
    }
  }
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    if (value && !isValidPhoneNumber(value)) {
      setError('Invalid phone number');
    } else {
      setError('');
      let temp_new_patient = updatedPatient;
      temp_new_patient["phone_number"] = value;
      setUpdatedPatient(temp_new_patient)
    }
  };
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setPatientEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
      let temp_new_patient = updatedPatient;
      temp_new_patient[event.target.name] = newEmail;
      setUpdatedPatient(temp_new_patient)
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^\w+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  return(
    <div className="modal-wrapper" style={{overflow: 'scroll', display: `${showPatientEdit ? 'flex' : 'none'}`, }}>
      <div className="modal " style={{minWidth: 900}}>
        <div className="modal-header">
          <h1 className="modal-heading">Edit Patient</h1>
          <a href="#" onClick={(event)=>{setShowPatientEdit(false)}} className="link-2"><span className="text-span-3 close"></span></a>
        </div>
        <div className="modal-body">
          <div className="w-form" style={{overflowX: 'auto',height: '750'}}>
            <label htmlFor="name" className="modal-label">First Name</label>
            <input
              type="text"
              className="modal-input no-mb w-input"
              name="first_name"
              onChange={(event)=>handleInputChange(event)}
              className="modal-input w-input"
              defaultValue={updatedPatient.first_name}
            />
            <label htmlFor="name" className="modal-label">Middle Name</label>
            <input
              type="text"
              className="modal-input w-input"
              name="middle_name"
              onChange={(event)=>handleInputChange(event)}
              defaultValue={updatedPatient.middle_name}
            />
            <label htmlFor="name" className="modal-label">Last Name</label>
            <input
              type="text"
              className="modal-input no-mb w-input"
              name="last_name"
              onChange={(event)=>handleInputChange(event)}
              defaultValue={updatedPatient.last_name}
            />
            <label htmlFor='email' className='modal-label'>
              Email*
            </label>
            <input
              type="email"
              name='patient_email'
              id='patient_email'
              className={`modal-input no-mb w-input ${ error ? 'is-invalid' : ''}`}
              placeholder="Enter email"
              defaultValue={updatedPatient.patient_email}
              onChange={event => {handleEmailChange(event)}}
            />
            {emailError && <div className="invalid-feedback"  style={{ color: 'red' }}>{emailError}</div>}
            <label htmlFor='phone_number' className='modal-label'>
              Phone Number*
            </label>
            <PhoneInput
              className='modal-input no-mb w-input'
              id='phone_number'
              name='phone_number'
              placeholder="Enter phone number"
              value={updatedPatient.phone_number}
              error={error}
              onChange={handlePhoneChange}/>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <label htmlFor="name" className="modal-label">Date of Birth</label>
            <input
              type="date"
              className="modal-input no-mb w-input"
              name="date_of_birth"
              onChange={(event)=>handleInputChange(event)}
              defaultValue={updatedPatient.date_of_birth}
            />
            <label htmlFor="name" className="modal-label">Sex</label>
            <select
              name="sex"
              onChange={(event)=>handleInputChange(event)}
              className="modal-input no-mb w-input"
              defaultValue={updatedPatient.sex}
            >
              <option value ="not_specified">Not specified</option>
              <option value ="male">Male</option>
              <option value ="female">Female</option>
            </select>
          </div>
        </div>
        <div style={{marginBottom: '15px',marginLeft: '10px'}} className="modal-footer">
          <div className="right">
            <a onClick={(event)=>{setShowPatientEdit(false)}} href="#" className="modal-btn cancel w-button close">Cancel</a>
            <a onClick={(event)=>{handleSubmit()}} href="#" className="modal-btn success w-button">Save</a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PatientEdit;