import fetchClient from '../config/client';

const createFfdNote = async (data) => {
  let response = await fetchClient
    .post('/coordinator/service_request_ffds', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

const getFfdNoteByID = async (id) => {
  let response = await fetchClient
    .get(`/coordinator/service_request_ffds/${id}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      return error;
    });
  return response;
};

const updateFfdNoteByID = async (id, data) => {
  let response = await fetchClient
    .put(`/coordinator/service_request_ffds/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

const updateServiceItemStatus = async (id, data) => {
  let response = await fetchClient
    .put(`/coordinator/service_request_items/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

const deleteFfdNote = async (id) => {
  let response = await fetchClient
    .delete(`coordinator/service_request_ffds/${id}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

const getPatientBaseServiceRequestItems = async (id,q_params) => {
  let serviceItems = await fetchClient
    .get(`/coordinator/patients_history/${id}/patient_base_service_items/`, { params: q_params })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return serviceItems;
};

const getPatientBaseDocumentAttachments = async (q_params) => {
  let serviceItems = await fetchClient
    .get(`/coordinator/patients_history/patient_base_document_attachments/`, { params: q_params })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return serviceItems;
};




export { createFfdNote, updateServiceItemStatus, deleteFfdNote, getFfdNoteByID, updateFfdNoteByID, getPatientBaseServiceRequestItems, getPatientBaseDocumentAttachments};
