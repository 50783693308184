import fetchClient from "../../config/client";


const getClientStandards = async (q_params) => {
  let standards = await fetchClient
		.get("client/standards.json",{ params: q_params })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return standards;
};
export const ClientStandardsActions = {
	getClientStandards,

};

export default ClientStandardsActions;
