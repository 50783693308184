import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { ServiceRequestDocumentActions } from '../../actions/service_request_document_reviews';

// service request items
const { getServiceRequestDocuments } = ServiceRequestDocumentActions;

const initialState = {
  loading: 'idle',
  error: '',
  data: null,
  clicked_item: null,
};

export const fetch_documents_list = createAsyncThunk('service_request/documents_list', async (id, thunkAPI) => {
  try {
    const response = await getServiceRequestDocuments({
      search: { service_request_id_eq: id },
    });
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const document_list_Slice = createSlice({
  name: 'service_request_document_list',
  initialState,

  reducers: {
    clicked_item: (state, action) => {
      return {
        ...state,
        clicked_item: action.payload,
      };
    },
    removed_item: (state) => {
      return {
        ...state,
        clicked_item: null,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetch_documents_list.pending, (state) => {
      state.data = [];
      state.loading = 'loading';
    });
    builder.addCase(fetch_documents_list.fulfilled, (state, data) => {
      state.data = data.payload;
      state.loading = 'loaded';
    });
    builder.addCase(fetch_documents_list.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
  },
});

export const { clicked_item, removed_item } = document_list_Slice.actions;

export default document_list_Slice.reducer;
