import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createFfdNote, updateServiceItemStatus, getFfdNoteByID, updateFfdNoteByID } from '../../actions/service_request_ffd';

// import {  } from "../../actions/service_request_ffd";

const initialState = { loading: 'idle', error: '', data: null };

export const createFFD = createAsyncThunk('ffd/createFFD', async (data, thunkAPI) => {
  try {
    const response = await createFfdNote(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getFfdById = createAsyncThunk('ffd/getFfdById', async (id, thunkAPI) => {
  try {
    const response = await getFfdNoteByID(id);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const updateFfd = createAsyncThunk('ffd/updateFfdById', async (data, thunkAPI) => {
  try {
    const response = await updateFfdNoteByID(data.service_request_ffd.ffd_id, data.service_request_ffd.data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const updateTaskStatus = createAsyncThunk('ffd/updateTaskStatus', async (data, thunkAPI) => {
  try {
    const response = await updateServiceItemStatus(data.id, data.payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const ffd_Slice = createSlice({
  name: 'service_request_ffd',
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(createFFD.pending, (state) => {
      state.data = [];
      state.loading = 'loading';
    });
    builder.addCase(createFFD.fulfilled, (state, data) => {
      state.data = data.payload;
      state.loading = 'loaded';
    });
    builder.addCase(createFFD.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    // get ffd by ID
    builder.addCase(getFfdById.pending, (state) => {
      state.data = [];
      state.loading = 'loading';
    });
    builder.addCase(getFfdById.fulfilled, (state, data) => {
      state.data = data.payload;
      state.loading = 'loaded';
    });
    builder.addCase(getFfdById.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    // update task
    builder.addCase(updateTaskStatus.pending, (state) => {
      state.data = [];
      state.loading = 'loading';
    });
    builder.addCase(updateTaskStatus.fulfilled, (state, data) => {
      state.loading = 'loaded';
    });
    builder.addCase(updateTaskStatus.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    //update FFD
    builder.addCase(updateFfd.pending, (state) => {
      state.data = [];
      state.loading = 'loading';
    });
    builder.addCase(updateFfd.fulfilled, (state, data) => {
      state.loading = 'loaded';
    });
    builder.addCase(updateFfd.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
  },
});

export const {} = ffd_Slice.actions;
export default ffd_Slice.reducer;
