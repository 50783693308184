import fetchClient from '../../config/client';

const getList = async (q_params) => {
  let serviceRequests = await fetchClient
    .get('client/service_requests.json', { params: q_params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return serviceRequests;
};
const getServiceRequestEvents = async (q_params) => {
  let serviceRequest = await fetchClient
    .get(`client/service_request_events.json`, { params: q_params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return serviceRequest;
};

// download the list of service request
const getListOfServiceRequest = async (q_params) => {
  let serviceRequests = await fetchClient
    .get('client/service_requests/download.json', { params: q_params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return serviceRequests;
};

// for add Service Request

const addServiceRequest = async (data) => {
  let response = await fetchClient
    .post(
      '/client/service_requests',
      { service_request: data },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then(function (res) {
      return res;
    });
  return response;
};

export const ClientServiceRequestActions = {
  getList,
  getServiceRequestEvents,
  getListOfServiceRequest,
  addServiceRequest,
};

export default ClientServiceRequestActions;
