const showErrors = (response) => {
  var elements = document.getElementsByClassName('form-errors');
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }
  var inputs = document.getElementsByClassName('wrong-input');
  while (inputs.length > 0) {
    inputs[0].classList.remove('wrong-input');
  }
  for (const property in response.data.errors) {
    let input = document.getElementById(`${property}`);
    if (input) {
      input.insertAdjacentHTML(
        'afterend',
        `<div id="error" class="form-errors">${response.data.errors[property]}</div>`
      );
      input.classList.add('wrong-input');
    }
  }
};

const customerNotes = (action, response) => {
  // Only Note Field exists in Customer Notes
  if (action === 'new') {
    return response.data.messages[0];
  }
};

export const FormValidator = {
  showErrors,
  customerNotes,
};

export default FormValidator;
