import fetchClient from '../config/client'

const getList = async (q_params) => {
  let serviceItems = await fetchClient
    .get('/coordinator/service_request_items.json', { params: q_params })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
      return []
    })
  return serviceItems
}

const getPatientServiceItemsList = async (q_params) => {
  let serviceItems = await fetchClient
    .get("/coordinator/service_request_items/patient_service_items.json", { params: q_params })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
      return []
    })
  return serviceItems
}
const updateServiceRequestItem = async (itemId, q_params) => {
  let serviceItems = await fetchClient
    .put(`/coordinator/service_request_items/${itemId}.json`, q_params)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
      return []
    })
  return serviceItems
}

const createList = async (q_params) => {
  let serviceItems = await fetchClient
    .post('/coordinator/admin_fees.json', q_params)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
      return []
    })
  return serviceItems
}

const updateItems = async (itemId, q_params) => {
  let serviceItems = await fetchClient
    .put(`/coordinator/admin_fees/${itemId}.json`, q_params)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
      return []
    })
  return serviceItems
}

const updateAllItemsStatus = async (data) => {
  let serviceItems = await fetchClient
    .post(`/coordinator/service_request_items/bulk_update`, data)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
      return []
    })
  return serviceItems
}

export const ServiceRequestItems = {
  getList,
  getPatientServiceItemsList,
  createList,
  updateItems,
  updateAllItemsStatus,
  updateServiceRequestItem
}

export default ServiceRequestItems
