import up from "../../assets/images/sort-up.svg";
import down from "../../assets/images/sort-down.svg";
const LinksHeader = ({label, column, orderField, handleSort, sort})=>{
  const ActiveSort = ({sortType,icon})=>{
    if(sortType == sort){
      return(
        <a href="#">
          {/* <i><img src={sortType=="asc" ? up : down} alt=""/></i> */}
        </a>
      );
    }else{
      return(
        <a href="#" onClick={(event)=>{handleSort(event, column, sortType )}}>
          <i><img src={sortType=="asc" ? up : down} alt=""/></i>
        </a>
      );
    }

  }
  if(orderField == column){
    return(
      <div className="sorting-head">
        <span>
          <ActiveSort sortType='desc'/>
          <ActiveSort
            sortType='asc'
          />
        </span>{label}
      </div>
    );
  }else{
    return(
      <div className="sorting-head">
        <span>
          <a href="#" onClick={(event)=>{handleSort(event, column, 'desc' )}}>
            <i><img src={down} alt=""/></i>
          </a>
          <a href="#" onClick={(event)=>{handleSort(event, column, 'asc' )}}>
            <i><img src={up} alt=""/></i>
          </a>
        </span>{label}
      </div>
    );
  }
}
export default LinksHeader;