import fetchClient from '../config/client';
const getList = async (q_params) => {
  let serviceRequests = await fetchClient
    .get('coordinator/service_requests.json', { params: q_params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return serviceRequests;
};
const getServiceRequest = async (id) => {
  let serviceRequest = await fetchClient
    .get(`coordinator/service_requests/${id}.json`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return serviceRequest;
};

const updateServiceRequest = async (id, data) => {
  let response = await fetchClient
    .put(`/coordinator/service_requests/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

const addServiceRequest = async (data) => {
  let response = await fetchClient
    .post(
      '/coordinator/service_requests',
      { service_request: data },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then(function (res) {
      return res;
    });
  return response;
};
export const ServiceRequestActions = {
  getList,
  getServiceRequest,
  addServiceRequest,
  updateServiceRequest,
};

export default ServiceRequestActions;
