import fetchClient from "../config/client";
import ProjectActions from "./projects";
const createPatient = async (data) => {
  let response = await fetchClient
    .post("/coordinator/patients", data,{headers: {'Content-Type': 'multipart/form-data'}}
    )
    .then(function (res) {
      return res;
    })
  return response;
};
const updatePatient = async (id, data) => {
  let response = await fetchClient
    .put(`/coordinator/patients/${id}`, data,{headers: {'Content-Type': 'multipart/form-data'}}
    )
    .then(function (res) {
      return res;
    })
  return response;
};

const getList = async (q_params) => {
  let patients = await fetchClient
    .get("coordinator/patients.json", { params: q_params })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return patients;
};
export const PatientActions = {
  getList,
  createPatient,
  updatePatient
};

export default PatientActions;
