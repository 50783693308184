import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: '',
  data: {
    id: '',
    name: '',
    subject: '',
    slug: '',
    bcc: '',
    cc: '',
    body: '',
    from_email: '',
    reply_to: '',
    to_email: '',
    attachments: [],
  },
  current_slug: '',
};

export const email_template_slice = createSlice({
  name: 'email_template',
  initialState,

  reducers: {
    setBase: (state, action) => {
      state = initialState;
      return state;
    },
    setCurrentSlug: (state, action) => {
      state.current_slug = action.payload;
      return state;
    },
    updateTemplate: (state, action) => {
      state.data[Object.keys(action.payload)[0]] = action.payload[Object.keys(action.payload)[0]].value;
      return state;
    },
    setTemplate: (state, action) => {
      state.data = {
        ...state.data,
        id: action.payload.id,
        name: action.payload.name,
        subject: action.payload.subject,
        bcc: action.payload.bcc,
        cc: action.payload.cc,
        body: action.payload.body,
        from_email: action.payload.from_email,
        reply_to: action.payload.reply_to,
        to_email: action.payload.to_email,
        attachments: action.payload.attachments,
      };

      return state;
    },
  },
});

export const { reducer, actions } = email_template_slice;
export default reducer;
