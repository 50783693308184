import React from 'react';
import './assets/css/normalize.css';
import './assets/css/webflow.css';
import './assets/css/style.css';
import './assets/css/rmi-39faa7.webflow.css';
import './assets/css/custom.css';
import { Route, Switch } from 'react-router-dom';
import ServiceRequestsList from "./components/coordinator/service_request/service_requests_list";

// dummy
// import HomePage from "./components/homepage/Homepage";
const Homepage = React.lazy(() => import('./components/homepage/Homepage'));
const Customers = React.lazy(() => import('./components/customers/Customers'));
const EmailIndex = React.lazy(() => import('./components/admin/emails'));
const ServiceItems = React.lazy(() => import('./components/serviceItems/ServiceItems'));
const Standards = React.lazy(() => import('./components/standards/Standards'));
const Projects = React.lazy(() => import('./components/projects/Projects'));
const Users = React.lazy(() => import('./components/users/Users'));
const PinVerification = React.lazy(() => import('./components/login/PinVerification'));
const ForgotPassword = React.lazy(() => import('./components/password/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./components/password/ResetPassword'));
const Login = React.lazy(() => import('./components/login/Login'));
const Layout = React.lazy(() => import('./layout/layout'));
const Notes = React.lazy(() => import('./components/admin/notes/case_notes'));
const EmailConfiguration = React.lazy(() => import('./components/admin/emails/configuration'));
const EmailTemplates = React.lazy(() => import('./components/admin/emails/templates/templates'));
const AdminTokens = React.lazy(() => import('./components/admin/emails/admin_tokens'));
const ServiceRequest = React.lazy(() => import('./components/coordinator/service_request/show'));
const ServiceRequestDocumentReviews = React.lazy(() =>
  import(
    './components/coordinator/service_request/service_request_document_reviews'
  )
);
const ServiceRequestDocumentReviewsEdit = React.lazy(() =>
  import(
    './components/coordinator/service_request/service_request_document_reviews_edit'
  )
);
const ServiceRequestAppointmentEdit = React.lazy(() =>
  import(
    './components/coordinator/service_request/service_request_appointment_edit'
  )
);
const ServiceRequestPsq = React.lazy(() =>
  import('./components/coordinator/service_request/service_request_psq')
);
const ServiceRequestFFD = React.lazy(() =>
  import('./components/coordinator/service_request/service_request_ffd')
);
const ServiceRequestFFDEdit = React.lazy(() =>
  import('./components/coordinator/service_request/service_request_ffd_edit')
);
const ServiceRequestAppointment = React.lazy(() =>
  import('./components/coordinator/service_request/service_request_appointment')
);

const AccountReceivable = React.lazy(() =>
  import('./components/billing/accountReceivable')
);
const ServiceRequestActivityLogs = React.lazy(() =>
  import('./components/billing/serviceRequestActivityLogs')
);
const ServiceRequests = React.lazy(() => import('./components/admin/serviceRequests/service_requests'));
const BillingItems    = React.lazy(() => import('./components/billing/billingItems'));
const CoordinatorBaseBillingItems    = React.lazy(() => import('./components/billing/coordinatorBaseBillingItems'));
const PrivateRoute    = React.lazy(() => import('./routes/private_route'));

const App = () => {
  return (
    <Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/login/pin_verification'   component={PinVerification} />
      <Route exact path='/password/forgot_password' component={ForgotPassword} />
      <Route exact path='/password/reset_password'  component={ResetPassword} />
      <Layout>
        <PrivateRoute
          exact
          path='/service_requests/:id'
          component={ServiceRequest}
        />
        <PrivateRoute
          exact
          path='/service_requests/:id/reviews'
          component={ServiceRequestDocumentReviews}
        />
        <PrivateRoute
          exact
          path='/service_requests/:id/ffd'
          component={ServiceRequestFFD}
        />
        <PrivateRoute
          exact
          path='/service_requests/:id/reviews/:reviews_id'
          component={ServiceRequestDocumentReviewsEdit}
        />
        <PrivateRoute
          exact
          path='/service_requests/:id/appointments'
          component={ServiceRequestAppointment}
        />
        <PrivateRoute
          exact
          path='/service_requests/:id/appointments/:appointment_id'
          component={ServiceRequestAppointmentEdit}
        />
        <PrivateRoute
          exact
          path='/service_requests/:id/ffd/:ffd_id'
          component={ServiceRequestFFDEdit}
        />
        <PrivateRoute
          exact
          path='/service_requests/:id/psq/:psq_id'
          component={ServiceRequestPsq}
        />
        <PrivateRoute exact path='/' component={Homepage} />
        <PrivateRoute exact path='/emails' component={EmailIndex} />
        <PrivateRoute exact path='/customers' component={Customers} />
        <PrivateRoute exact path='/service-items' component={ServiceItems} />
        <PrivateRoute
          exact
          path='/email-configuration'
          component={EmailConfiguration}
        />
        <PrivateRoute exact path='/templates' component={EmailTemplates} />
        <PrivateRoute exact path='/admin_tokens' component={AdminTokens} />
        <PrivateRoute exact path='/standards' component={Standards} />
        <PrivateRoute exact path='/projects' component={Projects} />
        <PrivateRoute exact path='/users' component={Users} />
        <PrivateRoute exact path='/notes' component={Notes} />
        <PrivateRoute exact path='/account_receivable' component={AccountReceivable} />
        <PrivateRoute exact path='/billingItems' component={BillingItems} />
        <PrivateRoute exact path='/coordinator_base_billing_items' component={CoordinatorBaseBillingItems} />
        <PrivateRoute exact path='/closed_cases' component={ServiceRequestActivityLogs} />
        <PrivateRoute exact path='/service_requests' component={ServiceRequests} />
        <PrivateRoute exact path='/change_password'  component={ResetPassword} />
      </Layout>
    </Switch>
  );
};
export default App;
