import { Link } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import LinksHeader from '../common/link_header';
import Unread from '../../assets/images/unread.svg';
import Read from '../../assets/images/read.svg';
import Green from '../../assets/images/green.svg';
import Red from '../../assets/images/red.svg';
import Yellow from '../../assets/images/orange.svg';
import RecentReviewIcon from '../../assets/images/recent_review_icon.png';
import { dateFormat } from '../../utils/date_format';
const ServiceRequestsTableList = ({
  serviceRequests,
  change_handle_sort,
  updated_note,
}) => {
  const [orderField, setOrderField] = useState('new_from_customer');
  const [sort, setSort] = useState('desc');
  const updateNoteFieldHandler = (event, id) => {
    event.preventDefault();
    updated_note(id, event.target.value);
  };

  const handleSort = async (event, column, n_sort) => {
    event.preventDefault();
    setOrderField(column);
    setSort(n_sort);
  };
  useEffect(() => {
    change_handle_sort(sort, orderField);
  }, [orderField, sort]);

  return (
    <>
      <table className='table-list'>
        <thead>
          <tr>
            <th></th>
            <th>
              <LinksHeader
                label='New?'
                column='new_from_customer'
                orderField={orderField}
                handleSort={handleSort}
                sort={sort}
              />
            </th>
            <th>
              <LinksHeader
                label='Recent Review'
                column='result_received'
                orderField={orderField}
                handleSort={handleSort}
                sort={sort}
              />
            </th>
            <th>
              <LinksHeader
                label='FFD'
                column='last_ffd_status'
                orderField={orderField}
                handleSort={handleSort}
                sort={sort}
              />
            </th>
            <th>
              <LinksHeader
                label='Customer'
                column='customer_name'
                orderField={orderField}
                handleSort={handleSort}
                sort={sort}
              />
            </th>
            <th>
              <LinksHeader
                label='Project'
                column='project_name'
                orderField={orderField}
                handleSort={handleSort}
                sort={sort}
              />
            </th>
            <th>
              <LinksHeader
                label='Standard'
                column='standard_name'
                orderField={orderField}
                handleSort={handleSort}
                sort={sort}
              />
            </th>
            <th>
              <LinksHeader
                label='Patient'
                column='patient_full_name'
                orderField={orderField}
                handleSort={handleSort}
                sort={sort}
              />
            </th>
            {/*<th>*/}
            {/*  <LinksHeader*/}
            {/*    label='Deadline'*/}
            {/*    column='deadline'*/}
            {/*    orderField={orderField}*/}
            {/*    handleSort={handleSort}*/}
            {/*    sort={sort}*/}
            {/*  />*/}
            {/*</th>*/}
            <th>
              <LinksHeader
                label='Note'
                column='coordinator_note'
                orderField={orderField}
                handleSort={handleSort}
                sort={sort}
              />
            </th>
            <th>
              Assigned Users
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {serviceRequests?.map((serviceRequest) => {
            return (
              <tr key={serviceRequest.id}>
                <td>
                  {serviceRequest.case_status == 'NewServiceRequest' ? (
                    <img src={Read} alt='Read' className='read-unread' />
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  {serviceRequest.new_from_customer ? (
                    <img src={Unread} alt='Unread' className='read-unread' />
                  ) : (
                    <></>
                    // <img src={Read} alt='Read' className='read-unread' />
                  )}
                </td>
                <td className='recent_review-row'>
                  {serviceRequest.case_status == 'Review' ? (
                    <img src={RecentReviewIcon} className='recent_review'></img>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  <>
                    {serviceRequest.last_ffd_status == 'green' ? (
                      <img src={Green}></img>
                    ) : serviceRequest.last_ffd_status == 'red' ? (
                      <img src={Red}></img>
                    ) : serviceRequest.last_ffd_status == 'yellow' ? (
                      <img src={Yellow}></img>
                    ) : (
                      <></>
                    )}
                  </>
                </td>
                <td>{serviceRequest.customer_name}</td>
                <td>{serviceRequest.project_name}</td>
                <td>{serviceRequest.standard_name}</td>
                <td>
                  {serviceRequest.patient_full_name}
                  {}
                </td>
                {/*<td>{dateFormat(serviceRequest.deadline, 'mm/dd/yyyy')}</td>*/}
                <td>
                  <textarea
                    defaultValue={serviceRequest.coordinator_note}
                    onChange={(event) => updateNoteFieldHandler(event, serviceRequest.id)}
                    className='table-notes'
                  />
                </td>
                <td className="text-break">{serviceRequest.assigned_users}</td>
                <td>
                  <Link
                    className='btn btn-edit'
                    style={{ minWidth: '70px' }}
                    to={'/service_requests/' + serviceRequest.id}
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ServiceRequestsTableList;
