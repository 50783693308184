import { useEffect, useState } from 'react';
import PatientActions from '../../../actions/patients';
import Pagination from '../../pagination/Pagination';
import PatientNew from './new';
import PatientEdit from "./edit";
import ClientPatientActions from '../../../actions/clientActions/patients';
// use Update Effect
import useUpdateEffect from '../../../utils/useUpdateEffect';

const PatientIndex = ({
  patient,
  patientPicked,
  showPatientsList,
  setShowPatientsList,
}) => {
  const [patients, setPatients] = useState([]);
  const [search, setSearch] = useState('');
  const [showPatientNew, setShowPatientNew] = useState(false);
  const [currentPatient, setCurrentPatient] = useState({
    first_name:     patient.first_name,
    middle_name:    patient.middle_name,
    last_name:      patient.last_name,
    patient_email:  patient.patient_email,
    phone_number:   patient.phone_number,
    date_of_birth:  patient.date_of_birth,
    sex:            patient.sex,
    id:             patient.id,
  });

  const [showPatientEdit,setShowPatientEdit] = useState(false);
  const [editPatient,setEditPatient] = useState({});
  // const [selectedPatient, setSelectedPatient] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [clickforcePage, setClickForce] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  let currentRole = localStorage.getItem('current_user_role');
  // search current Page
  const [searchPage, setSearchPage] = useState(1);

  const fetchPatients = async () => {
    let res;
    if (currentRole == 'client') {
      res = await ClientPatientActions.getList({
        page: currentPage,
      });
    } else {
      res = await PatientActions.getList({
        page: currentPage,
      });
    }
    setPageCount(res.pages);
    setPatients(res.patients);
  };
  function handlePageClick({ selected: selectedPage }) {
    if (search !== '') {
      setSearchPage(selectedPage + 1);
    } else {
      setCurrentPage(selectedPage + 1);
    }
    setClickForce(selectedPage);
  }
  const fetchPatientsOnPage = async () => {
    let res;
    if (currentRole == 'client') {
      res = await ClientPatientActions.getList({
        page: searchPage,
        search: {
          m: 'or',
          id_eq: search,
          first_name_cont: search,
          middle_name_cont: search,
          last_name_cont: search,
          full_name_cont: search,
          patient_email_cont: search,
          phone_number_cont: search,
        },
      });
    } else {
      res = await PatientActions.getList({
        page: searchPage,
        search: {
          m: 'or',
          id_eq: search,
          first_name_cont: search,
          middle_name_cont: search,
          last_name_cont: search,
          full_name_cont: search,
          patient_email_cont: search,
          phone_number_cont: search,
        },
      });
    }
    setPatients(res.patients);
  };
  const handleSearchChange = async (event) => {
    let res;
    setSearch(event.target.value);
    if (currentRole == 'client') {
      res = await ClientPatientActions.getList({
        search: {
          m: 'or',
          id_eq: event.target.value,
          first_name_cont: event.target.value,
          middle_name_cont: event.target.value,
          last_name_cont: event.target.value,
          full_name_cont: event.target.value,
          patient_email_cont: search,
          phone_number_cont: search,
        },
      });
    } else {
      res = await PatientActions.getList({
        search: {
          m: 'or',
          id_eq: event.target.value,
          first_name_cont: event.target.value,
          middle_name_cont: event.target.value,
          last_name_cont: event.target.value,
          full_name_cont: event.target.value,
          patient_email_cont: search,
          phone_number_cont: search,
        },
      });
    }
    setPageCount(res.pages);
    setPatients(res.patients);
  };
  const handleInputChange = (event) => {
    let t_patient = patients.find((p) => p.id === parseInt(event.target.value));
    setCurrentPatient(t_patient);
  };
  const finalizePatient = () => {
    patientPicked(currentPatient);
    setShowPatientsList(false);
  };
  const patientEditModal = (event,patient) => {
    event.preventDefault();
    setShowPatientEdit(true)
    setEditPatient(patient)
  }
  useEffect(() => {
    // Set a flag to check if the component is still mounted
    let isMounted = true;
    const fetchData = async () => {
      try {
        await fetchPatients();
        // Check if the component is still mounted before updating state
        if (isMounted) {
          fetchPatients();
          // Here, res is accessible
          // Update the state or perform other actions if needed
        }
      } catch (error) {
        // Handle error
      }
    };
    fetchData();
    // Cleanup function to run when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [currentPage]);

  useUpdateEffect(() => {
    fetchPatientsOnPage();
  }, [searchPage]);
  return (
    <div>
      <div
        className='modal-wrapper'
        style={{
          overflow: 'scroll',
          display: `${showPatientsList ? 'flex' : 'none'}`,
        }}
      >
        <div className='modal ' style={{ minWidth: 900 }}>
          <div className='modal-header'>
            <h1 className='modal-heading'>Find & Edit Patients</h1>
            <a href='#' onClick={(event) => {setShowPatientsList(false)}} className='link-2'>
              <span className='text-span-3 close'></span>
            </a>
          </div>
          <div className='modal-body'>
            <div className='w-form' style={{ overflowX: 'auto', height: '750' }}>
              <div className='action-bar'>
                <section id='contact-form' className='contact-form'>
                  <div className='form-block-2 w-form'>
                    <div className='contact-form-grid'>
                      <div className='div-block-3'>
                        <div className='filedset mr-20'>
                          <label className='modal-label'>Search</label>
                          <input
                            placeholder='Name'
                            type='text'
                            className='modal-input no-mb w-input'
                            maxLength='256'
                            value={search}
                            onChange={(event) => handleSearchChange(event)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='action-panel-right' style={{ width: '50%', paddingLeft: '10px' }}>
                    <div className='right'>
                      <a onClick={(event) => setShowPatientNew(true)} className='modal-btn success w-button'>
                        <span className='ico-add'></span>New
                      </a>
                      <a href='#' onClick={(event) => {setShowPatientsList(false)}} className='modal-btn cancel w-button close'>
                        Cancel
                      </a>
                      <a
                        href='#'
                        onClick={(event) => {
                          finalizePatient();
                        }}
                        className='modal-btn success w-button'
                      >
                        Save
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div className='coordinator-wrapper '>
                <table className='table-list'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Patient Id</th>
                      <th>First Name</th>
                      <th>Middle Name</th>
                      <th>Last Name</th>
                      {/*This code is not use because this is related to patient history (PH)*/}
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Sex</th>
                      <th>Date of Birth</th>
                      <th>Customer</th>
                      {/*This code is not use because this is related to patient history (PH)*/}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patients.map((p, ind) => {
                      return (
                        <tr key={ind}>
                          <td>
                            <input
                              checked={
                                p.id === currentPatient.id ? true : false
                              }
                              onChange={(event) => handleInputChange(event)}
                              value={p.id}
                              type='radio'
                              name='patient_id'
                            />
                          </td>
                          <td>{p.id}</td>
                          <td>{p.first_name}</td>
                          <td>{p.middle_name}</td>
                          <td>{p.last_name}</td>
                          <td>{p.patient_email}</td>
                          <td>{p.phone_number}</td>
                          <td>{p.sex}</td>
                          <td>{p.date_of_birth}</td>
                          <td>{p.customer_name}</td>
                          {/*This code is not use because this is related to patient history (PH)*/}
                          <td><a onClick={(event) => patientEditModal(event,p)} className='btn-edit'>Edit</a></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className='modal-footer'>
                <div className='right'>
                  <Pagination
                    handlePageClick={handlePageClick}
                    pageCount={pageCount}
                    clickforcePage={clickforcePage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PatientNew
        fetchPatients={fetchPatients}
        showPatientNew={showPatientNew}
        setShowPatientNew={setShowPatientNew}
      />
      { showPatientEdit &&
        <PatientEdit
          setEditPatient={setEditPatient}
          editPatient={editPatient}
          setShowPatientEdit={setShowPatientEdit}
          showPatientEdit={showPatientEdit}
        />

      }

    </div>
  );
};
export default PatientIndex;
