import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ServiceRequestActions } from '../../actions/service_requests'

const initialState = { loading: 'idle', error: '', data: null }

export const fetch_service_request = createAsyncThunk(
  'service_request',
  async (id, thunkAPI) => {
    try {
      const response = await ServiceRequestActions.getServiceRequest(id)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  }
)

export const service_request_slice = createSlice({
  name: 'service_request',
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetch_service_request.pending, (state) => {
      state.data = []
      state.loading = 'loading'
    })
    builder.addCase(fetch_service_request.fulfilled, (state, data) => {
      state.data = data.payload
      state.loading = 'loaded'
    })
    builder.addCase(fetch_service_request.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message
    })
  }
})

export const {} = service_request_slice.actions
export default service_request_slice.reducer
