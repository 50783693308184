import axios from 'axios';
const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_BASE_URL,
  };

  let instance = axios.create(defaultOptions);
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers = {
      Authorization: token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json',
    };
    return config;
  });

  instance.interceptors.response.use(
    function (successRes) {
      return successRes;
    },
    function (error) {
      if (error.response.status === 403) {
        alert('Session is expired please login again!');
        localStorage.removeItem('token');
        localStorage.removeItem('mfa_alert');
        window.location.replace('/login');
      } else if (error.response.status === 406) {
        alert('Please complete the process of Multi Factor Authentication');
        window.location.replace('/login/pin_verification');
      }else{
        return error.response;
      }
    }
  );
  return instance;
};

export default fetchClient();
