import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: '',
  data: {},
  currently_selected: [],
  removed_service_items: [],
  current_admin_fees: {
    id: -1,
    service_request_id: -1,
    service_request_item_ids: [],
    new_service_items: [],
    service_request_event_id: -1,
    reviewed_service_items: [],
  },
};

export const admin_fees_slice = createSlice({
  name: 'admin_fees',
  initialState,

  reducers: {
    setBase: (state, action) => {
      return {
        ...state,
        current_admin_fees: {
          ...state.current_admin_fees,
          id: action.payload.id,
          service_request_id: action.payload.service_request_id,
          service_request_event_id: action.payload.service_request_event_id,
          new_service_items: action.payload.new_service_items,
          reviewed_service_items: action.payload.reviewed_service_items,
        },
      };
    },
    updateItemStatus: (state, action) => {
      if (action.payload.new_status === 'Reviewed') {
        // Move items from "New" to "Reviewed"
        let reviewed_items_temp = [
          ...state.current_admin_fees.reviewed_service_items,
        ];
        let new_items_temp = [...state.current_admin_fees.new_service_items];
        let currently_selected_temp = [...state.currently_selected];

        state.current_admin_fees.new_service_items.forEach((single_item) => {
          if (state.currently_selected.includes(single_item.id)) {
            single_item.status = 'Reviewed';
            reviewed_items_temp.push(single_item);
          }
        });

        state.current_admin_fees.reviewed_service_items = reviewed_items_temp;

        // Now remove items from 'new_service_items'
        state.current_admin_fees.new_service_items = [];
        new_items_temp.forEach((single_item) => {
          if (state.currently_selected.includes(single_item.id)) {
          } else {
            state.current_admin_fees.new_service_items.push(single_item);
          }
        });
        state.current_admin_fees.new_service_items.filter(
          (single_item) => !currently_selected_temp.includes(single_item.id)
        );

        state.currently_selected = [];
      } else if (action.payload.new_status === 'New') {
        // Move items from "Reviewed" to "New"
        let reviewed_items_temp = [
          ...state.current_admin_fees.reviewed_service_items,
        ];
        let new_items_temp = [...state.current_admin_fees.new_service_items];
        let currently_selected_temp = [...state.currently_selected];
        let temp_removed_service_items = [...state.removed_service_items];

        state.current_admin_fees.reviewed_service_items.forEach(
          (single_item) => {
            if (state.currently_selected.includes(single_item.id)) {
              single_item.status = 'New';
              new_items_temp.push(single_item);
              temp_removed_service_items.push(single_item);
            }
          }
        );

        state.current_admin_fees.new_service_items = new_items_temp;
        state.removed_service_items = temp_removed_service_items;
        // Now remove items from 'reviewed_service_items'
        state.current_admin_fees.reviewed_service_items = [];
        reviewed_items_temp.forEach((single_item) => {
          if (state.currently_selected.includes(single_item.id)) {
          } else {
            state.current_admin_fees.reviewed_service_items.push(single_item);
          }
        });
        state.current_admin_fees.reviewed_service_items.filter(
          (single_item) => !currently_selected_temp.includes(single_item.id)
        );

        state.currently_selected = [];
      }

      return state;
    },
    resetAll: (state, action) => {
      state = initialState;
      return state;
    },
    addItem: (state, action) => {
      return {
        ...state,
        currently_selected: [...state.currently_selected, action.payload.id],
      };
    },
    removeItem: (state, action) => {
      let currently_selected_temp = [...state.currently_selected];
      currently_selected_temp = currently_selected_temp.filter(
        (item) => item !== action.payload.id
      );

      return {
        ...state,
        currently_selected: currently_selected_temp,
      };
    },
    resetClickedItems: (state, action) => {
      return {
        ...state,
        currently_selected: [],
      };
    },
  },
});

export const { reducer, actions } = admin_fees_slice;
export default reducer;
