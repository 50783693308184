import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceRequestItems } from '../../actions/service_request_items';

const initialState = {
  loading: false,
  error: '',
  data: {},
  clicked_item: null || [],
};

export const fetchServiceRequestItems = createAsyncThunk('service_request_items_list', async (params, thunkAPI) => {
  try {
    const data = {
      search: { service_request_id_eq: params.id },
      page: params.page_num,
    };

    const response = await ServiceRequestItems.getList(data);

    response.service_request_items.forEach((single_item, index) => {
      response.service_request_items[index].is_recently_updated = false;
    });

    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const service_request_items_slice = createSlice({
  name: 'ServiceRequestItemsList',
  initialState,

  reducers: {
    clickedItem: (state, action) => {
      return {
        ...state,
        clicked_item: [...state.clicked_item, action.payload],
      };
    },
    updateItemStatus: (state, action) => {
      let indexOfItem = -1;
      for (let index = 0; index < state.data.service_request_items.length; index++) {
        if (state.data.service_request_items[index].id === action.payload.single_item.id) {
          indexOfItem = index;
        }
      }

      state.data.service_request_items[indexOfItem].is_recently_updated = action.payload.is_recently_updated;
      state.data.service_request_items[indexOfItem].status = action.payload.new_status;
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchServiceRequestItems.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchServiceRequestItems.fulfilled, (state, data) => {
      state.data = data.payload;
      state.loading = false;
    });
    builder.addCase(fetchServiceRequestItems.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

const { actions, reducer } = service_request_items_slice;
export const { clickedItem, updateItemStatus } = actions;
// export const { reducer, actions } = service_request_items_slice;
export default reducer;
