import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createFfdNote,
  updateServiceItemStatus,
  getFfdNoteByID,
  updateFfdNoteByID,
} from "../../actions/service_request_ffd";

const initialState = { loading: "idle", error: "", data: null };

// export const createFFD = createAsyncThunk(
//   "ffd/createFFD",
//   async (data, thunkAPI) => {
//     try {
//       const response = await createFfdNote(data);
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue({ error: error.message });
//     }
//   }
// );

export const client_slice = createSlice({
  name: "client",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    // get ffd by ID
    // builder.addCase(getFfdById.pending, (state) => {
    //   state.data = [];
    //   state.loading = "loading";
    // });
    // builder.addCase(getFfdById.fulfilled, (state, data) => {
    //   state.data = data.payload;
    //   state.loading = "loaded";
    // });
    // builder.addCase(getFfdById.rejected, (state, action) => {
    //   state.loading = "error";
    //   state.error = action.error.message;
    // });
  },
});

export const {} = client_slice.actions;
export default client_slice.reducer;
