import fetchClient from "../config/client";

const getList = async (q_params) => {
  let projects = await fetchClient
    .get("/projects.json", { params: q_params })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return projects;
};

const getProject = async (id) => {
  let project = await fetchClient
    .get(`/projects/${id}.json`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return {};
    });
  return project;
};

const deleteProject = async (id) => {
  fetchClient
    .delete(`projects/${id}`)
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

const deleteProjectStandards = async (id, params) => {
  fetchClient
    .delete(`project_standards/${id}`, { data: params })
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

const updateProjectStandards = async (id, params) => {
  let project = fetchClient
    .put(`project_standards/${id}`, params)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
  return project;
};

const deleteProjectServiceItems = async (id, params) => {
  fetchClient
    .delete(`project_service_items/${id}`, { data: params })
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

const updateProjectServiceItems = async (id, params) => {
  let project = fetchClient
    .put(`project_service_items/${id}`, params)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
  return project;
};

const updateProject = async (id, params) => {
  let project = fetchClient
    .put(`projects/${id}`, params)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
  return project;
};

const createProject = async (params) => {
  let project = fetchClient
    .post(`projects`, params)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response.data;
    });
  return project;
};
const getStandards = async(project_id)=>{
  let standards = fetchClient
    .get(`projects/${project_id}/standards`)
    .then(function (response) {
      return response.data.standards;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
  return standards;
}
export const ProjectActions = {
  getList,
  getStandards,
  getProject,
  deleteProject,
  deleteProjectStandards,
  deleteProjectServiceItems,
  updateProject,
  updateProjectStandards,
  updateProjectServiceItems,
  createProject,
};

export default ProjectActions;
