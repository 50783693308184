import fetchClient from '../config/client';

const getServiceRequestItems = async (q_params) => {
  let serviceItems = await fetchClient
    .get('/coordinator/service_request_items.json', { params: q_params })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return serviceItems;
};

const getServiceRequestDocuments = async (q_params) => {
  let serviceRequestDocuments = await fetchClient
    .get('/coordinator/document_attachments.json', { params: q_params })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return serviceRequestDocuments;
};

const createServiceRequestDocmentReview = async (data) => {
  let response = await fetchClient
    .post('/coordinator/service_request_document_reviews', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

const deleteServiceRequestDocmentReview = async (q_params) => {
  let notes = await fetchClient
    .delete(`coordinator/service_request_document_reviews/${q_params}.json`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return notes;
};

const getServiceRequestDocmentReview = async (q_params) => {
  let notes = await fetchClient
    .get(`coordinator/service_request_document_reviews/${q_params}.json`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return notes;
};

const sendEmailForReview = async (data) => {
  let response = await fetchClient
    .post('/coordinator/emails', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

const sendPsqEmail = async (data) => {
  let response = await fetchClient
    .post('/coordinator/service_request_psqs', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

const updateServiceRequestDocmentReview = async (id, data) => {
  let response = await fetchClient
    .put(`/coordinator/service_request_document_reviews/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

const updateCaseNote = async (id, data) => {
  let response = await fetchClient
    .put(`/coordinator/case_notes/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

export const ServiceRequestDocumentActions = {
  getServiceRequestItems,
  getServiceRequestDocuments,
  createServiceRequestDocmentReview,
  deleteServiceRequestDocmentReview,
  getServiceRequestDocmentReview,
  sendEmailForReview,
  sendPsqEmail,
  updateServiceRequestDocmentReview,
};

export default ServiceRequestDocumentActions;
