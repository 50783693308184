import { useEffect, useState } from "react";
import PatientActions from "../../../actions/patients";
import FormValidator from "../../common/form_validator";
import ClientPatientActions from "../../../actions/clientActions/patients";
import "react-phone-number-input/style.css";
import PhoneInput,{isValidPhoneNumber} from "react-phone-number-input";

// utils
import { disableFutureDate } from "../../../utils/disabledFutureDate";

const PatientNew = ({ showPatientNew, setShowPatientNew, fetchPatients }) => {
  let currentRole = localStorage.getItem('current_user_role');
  const [phoneNumber, setPhoneNumber] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const [newPatient, setNewPatient] = useState({
    first_name:     "",
    last_name:      "",
    middle_name:    "",
    date_of_birth:  "",
    patient_email:  "",
    phone_number:   "",
    sex:            "not_specified",
  });

  const handleInputChange = (event) => {
    setNewPatient(prevPatient => ({
      ...prevPatient,
      [event.target.name]: event.target.value,
    }));
  };

  // const handleInputChange = (event) => {
  //   let temp_new_patient = newPatient;
  //   temp_new_patient[event.target.name] = event.target.value;
  //   setNewPatient(temp_new_patient);
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let res;
    if (currentRole == 'client') {
      res = await ClientPatientActions.createPatient({ patient: newPatient });
    } else {
      res = await PatientActions.createPatient({ patient: newPatient });
    }
    if (res.status === 200) {
      setError('')
      setPhoneNumber('')
      setPatientEmail('')
      setNewPatient({
        first_name:     '',
        last_name:      '',
        middle_name:    '',
        date_of_birth:  '',
        patient_email:  '',
        phone_number:   '',
        sex: 'not_specified',
      });
      fetchPatients();
      setShowPatientNew(false);
      FormValidator.showErrors(res);
    } else if (res.status == 422) {
      FormValidator.showErrors(res);
    }
  };
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    if (value && !isValidPhoneNumber(value)) {
      setError('Invalid phone number');
    } else {
      setError('');
      let temp_new_patient = newPatient;
      temp_new_patient["phone_number"] = value;
      setNewPatient(temp_new_patient)
    }
  };
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setPatientEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
      let temp_new_patient = newPatient;
      temp_new_patient[event.target.name] = newEmail;
      setNewPatient(temp_new_patient)
    }
  };
  const validateEmail = (email) => {

    const emailRegex = /^\w+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  return (
    <div
      className='modal-wrapper'
      style={{
        overflow: 'scroll',
        display: `${showPatientNew ? 'flex' : 'none'}`,
      }}
    >
      <div className='modal ' style={{ minWidth: 900 }}>
        <div className='modal-header'>
          <h1 className='modal-heading'>New Patient</h1>
          <a
            href='#'
            onClick={(event) => {
              setShowPatientNew(false);
            }}
            className='link-2'
          >
            <span className='text-span-3 close'></span>
          </a>
        </div>
        <div className='modal-body'>
          <div className='w-form' style={{ overflowX: 'auto', height: '750' }}>
            <label htmlFor='name' className='modal-label'>
              First Name*
            </label>
            <input
              type='text'
              className='modal-input no-mb w-input'
              name='first_name'
              id='first_name'
              value={newPatient.first_name}
              onChange={(event) => handleInputChange(event)}
            />
            <label htmlFor='name' className='modal-label'>
              Middle Name
            </label>
            <input
              type='text'
              className='modal-input w-input'
              name='middle_name'
              id='middle_name'
              value={newPatient.middle_name}
              onChange={(event) => handleInputChange(event)}
            />
            <label htmlFor='name' className='modal-label'>
              Last Name*
            </label>
            <input
              type='text'
              className='modal-input no-mb w-input'
              name='last_name'
              id='last_name'
              value={newPatient.last_name}
              onChange={(event) => handleInputChange(event)}
            />
            <label htmlFor='email' className='modal-label'>
              Email*
            </label>
            <input
              type="email"
              name='patient_email'
              id='patient_email'
              className={`modal-input no-mb w-input ${ error ? 'is-invalid' : ''}`}
              placeholder="Enter email"
              value={patientEmail}
              onChange={event => {handleEmailChange(event)}}
            />
            {emailError && <div className="invalid-feedback"  style={{ color: 'red' }}>{emailError}</div>}
            <label htmlFor='phone_number' className='modal-label'>
              Phone Number*
            </label>
            <PhoneInput
              className='modal-input no-mb w-input'
              id='phone_number'
              name='phone_number'
              placeholder="Enter phone number"
              value={phoneNumber}
              error={error}
              onChange={handlePhoneChange}/>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <label htmlFor='name' className='modal-label'>
              Date of Birth
            </label>
            <input
              type='date'
              className='modal-input no-mb w-input'
              name='date_of_birth'
              id='date_of_birth'
              value ={newPatient.date_of_birth}
              max={disableFutureDate()}
              onChange={(event) => handleInputChange(event)}
            />
            <label htmlFor='name' className='modal-label'>
              Sex
            </label>
            <select
              name='sex'
              value ={newPatient.sex}
              onChange={(event) => handleInputChange(event)}
              className='modal-input no-mb w-input'
              id='sex'
            >
              <option value='not_specified'>Not specified</option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </select>
          </div>
        </div>
        <div
          style={{ marginBottom: '15px', marginLeft: '10px' }}
          className='modal-footer'
        >
          <div className='right'>
            <a
              onClick={(event) => {
                setShowPatientNew(false);
              }}
              href='#'
              className='modal-btn cancel w-button close'
            >
              Cancel
            </a>
            <a onClick={(event) => { handleSubmit(event)}} href='#' className='modal-btn success w-button'>
              Save
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PatientNew;
