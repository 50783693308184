import React, { useEffect, useState } from 'react';
import CustomerActions from '../../../actions/customers';
import ProjectActions from '../../../actions/projects';
import ServiceRequestActions from '../../../actions/service_requests';
import PatientIndex from '../patients/index';
import PatientEdit from '../patients/edit';
import FormValidator from '../../common/form_validator';
// for client actions
import ClientServiceRequestActions from '../../../actions/clientActions/service_requests';
import ClientProjectsActions from '../../../actions/clientActions/projects';
import ClientStandardsActions from '../../../actions/clientActions/standards';

const ServiceRequestForm = ({
  showFormModal,
  setShowFormModal,
  fetchServiceRequests,
  is_refresh,
}) => {
  const [showPatientEdit, setShowPatientEdit] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [standards, setStandards] = useState([]);
  const [showPatientsList, setShowPatientsList] = useState(false);
  const [patient, setPatient] = useState({});
  const [notes, setNotes] = useState('');
  let currentRole = localStorage.getItem('current_user_role');
  let currentUser = JSON.parse(localStorage.getItem('user'));
  let currentCustomerName = localStorage.getItem('customerName');

  const fetchCustomers = async () => {
    let temp_customers = await CustomerActions.getProjectsCustomers();
    setCustomers(temp_customers.data);
  };
  const fetchClientProjects = async () => {
    let temp_projects = await ClientProjectsActions.getClientProjects();
    setProjects(temp_projects.projects);
  };
  useEffect(() => {
    if (currentRole == 'client') {
      fetchClientProjects();
    } else {
      fetchCustomers();
    }
  }, []);
  const [service_request, setServiceRequest] = useState({ project_id: '' });

  const createServiceRequest = async () => {
    let res;
    let patient_attributes = { contact_email: patient.patient_email, contact_phone: patient.phone_number}
    let data = {...service_request,...patient_attributes}
    if (currentRole == 'client') {
      res = await ClientServiceRequestActions.addServiceRequest(
        // service_request
        data
      );
    } else {
      // res = await ServiceRequestActions.addServiceRequest(service_request);
      res = await ServiceRequestActions.addServiceRequest(data);
    }
    if (res.status == 200) {
      setShowFormModal(false);
      if (is_refresh) {
        window.location.reload();
      } else {
        fetchServiceRequests();
        setServiceRequest({});
      }
    } else if (res.status == 422) {
      FormValidator.showErrors(res);
    }
  };

  const getProjects = async (customer_id) => {
    let temp_projects;
    if (currentRole === 'client') {
      // temp_projects = await ClientCustomerActions.getProjects(customer_id);
    } else {
      temp_projects = await CustomerActions.getProjects(customer_id);
    }

    setProjects(temp_projects);
  };
  const getStandards = async (project_id) => {
    let temp_standards = await ProjectActions.getStandards(project_id);
    setStandards(temp_standards);
  };

  const getClientStandards = async (project_id) => {
    let temp_standards = await ClientStandardsActions.getClientStandards({
      search: {
        projects_id_eq: project_id,
      },
    });
    setStandards(temp_standards.standards);
  };

  const projectSelected = (e) => {
    if (currentRole == 'client') {
      getClientStandards(e.target.value);
    } else {
      getStandards(e.target.value);
    }

    handleInputChange(e);
  };

  const handleInputChange = (e) => {
    let t_sr = service_request;
    if (e.target.name == 'notes') {
      if (currentRole == 'client') {
        t_sr['customer_notes_attributes'] = [
          {
            note_attributes: {
              [e.target.name]: e.target.value,
            },
          },
        ];
      } else if (currentRole == 'coordinator') {
        t_sr['customer_notes_attributes'] = [
          {
            new_from_customer: false,
            note_attributes: {
              [e.target.name]: e.target.value,
            },
          },
        ];
      }
    } else {
      t_sr[e.target.name] = e.target.value;
    }
    setServiceRequest(t_sr);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    createServiceRequest();
  };
  const patientPicked = (p) => {
    let temp_service_request = service_request;
    temp_service_request['patient_id'] = p.id;
    setPatient(p);
  };
  const RenderPatientList = () => {
    if (showPatientsList) {
      return (
        <PatientIndex
          patient={patient}
          patientPicked={patientPicked}
          showPatientsList={showPatientsList}
          setShowPatientsList={setShowPatientsList}
        />
      );
    } else {
      return <div></div>;
    }
  };
  const ShowEditForm = () => {
    if (showPatientEdit) {
      return (
        <PatientEdit
          setPatient={setPatient}
          patient={patient}
          setShowPatientEdit={setShowPatientEdit}
        />
      );
    } else {
      return <></>;
    }
  };
  const showEditPatient = () => {
    if (patient.constructor === Object && Object.keys(patient).length > 0) {
      setShowPatientEdit(true);
    }
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  };

  return (
    <div>
      <form
        id='email-form'
        name='email-form'
        data-name='Email Form'
        onSubmit={(event) => handleSubmit(event)}
      >
        <div
          className='modal-wrapper'
          style={{
            overflow: 'scroll',
            display: `${showFormModal ? 'flex' : 'none'}`,
          }}
        >
          <div className='modal ' style={{ minWidth: 900 }}>
            <div className='modal-header'>
              <h1 className='modal-heading'>New Service Request</h1>
              <a
                onClick={(event) => {
                  event.preventDefault();
                  setShowFormModal(false);
                }}
                className='link-2'
              >
                <span className='text-span-3 close'></span>
              </a>
            </div>
            <div className='modal-body'>
              <div
                className='w-form'
                style={{ overflowX: 'auto', height: '750' }}
              >
                {currentRole == 'client' && (
                  <>
                    <label className='modal-label'>{currentCustomerName}</label>
                  </>
                )}
                <div className='coordinator-wrapper '>
                  <div className='col'>
                    <h5 style={{ textAlign: 'left' }}>Service Request</h5>

                    {currentRole == 'coordinator' && (
                      <>
                        <label htmlFor='name' className='modal-label'>
                          Customer
                        </label>
                        <select
                          name='customer_id'
                          className='modal-input select'
                          onChange={(e) => getProjects(e.target.value)}
                          id='customer'
                        >
                          <option
                            value=''
                            disabled='disabled'
                            selected='selected'
                          >
                            Select Customer
                          </option>
                          {customers.map((customer) => {
                            return (
                              <option key={customer.id} value={customer.id}>
                                {customer.name}
                              </option>
                            );
                          })}
                        </select>
                      </>
                    )}
                    <label htmlFor='name' className='modal-label'>
                      Project*
                    </label>
                    <select
                      name='project_id'
                      className='modal-input select'
                      onChange={(e) => projectSelected(e)}
                      id='project'
                    >
                      <option value='' selected='true' disabled='disabled'>
                        Select Project
                      </option>
                      {projects.map((project) => {
                        return (
                          <option key={project.id} value={project.id}>
                            {project.name}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor='email' className='modal-label'>
                      Deadline*
                    </label>
                    <input
                      type='date'
                      className='modal-input'
                      name='deadline'
                      min={disablePastDate()}
                      onChange={(event) => handleInputChange(event)}
                      data-name='Deadline'
                      id='deadline'
                    />
                    <hr style={{ margin: '5px 0px' }} />

                    <h5 style={{ textAlign: 'left' }}>
                      Select a standard for this service request
                    </h5>
                    <label htmlFor='name' className='modal-label'>
                      Standard*
                    </label>
                    <select
                      name='standard_id'
                      className='modal-input select'
                      onChange={(event) => handleInputChange(event)}
                      id='standard'
                    >
                      <option value=''>Select Standard</option>
                      {standards.map((standard) => {
                        return (
                          <option key={standard.id} value={standard.id}>
                            {standard.name}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor='email' className='modal-label'>
                      Note
                    </label>
                    <textarea
                      type='text'
                      className='modal-input textarea w-input'
                      name='notes'
                      data-name='CoordinatorNote'
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className='col'>
                    <h5 style={{ textAlign: 'left' }}>Candidate Information</h5>
                    <div className='input-group '>
                      <label htmlFor='name' className='modal-label'>
                        Name*
                      </label>
                      <div className='name'>
                        <input
                          type='text'
                          className='modal-input'
                          value={patient.full_name}
                          className='modal-input select'
                          disabled={true}
                          id='patient'
                        />
                        <a
                          onClick={(event) => {
                            event.preventDefault();
                            showEditPatient();
                          }}
                          className='btn btn-copy'
                        >
                          View
                        </a>
                        <a
                          onClick={(event) => {
                            event.preventDefault();
                            setShowPatientsList(true);
                          }}
                          className='btn'
                        >
                          List
                        </a>
                      </div>
                      <label htmlFor='email' className='modal-label'>
                        Address
                      </label>
                      <textarea
                        type='text'
                        className='modal-input textarea w-input'
                        maxLength='256'
                        name='contact_address'
                        onChange={(event) => handleInputChange(event)}
                        data-name='ContactAddress'
                        id='contact_address'
                      />
                      <label htmlFor='name' className='modal-label'>
                        Phone
                      </label>
                      <input
                        type='text'
                        className='modal-input'
                        maxLength='13'
                        defaultValue={patient.phone_number}
                        name='contact_phone'
                        readOnly={true}
                        // onChange={(event) => handleInputChange(event)}
                        data-name='ContactPhone'
                        id='contact_phone'
                      />
                      <label
                        htmlFor='contact_alternate_phone'
                        className='modal-label'
                      >
                        Alternate Phone
                      </label>
                      <input
                        name='contact_alternate_phone'
                        onChange={(event) => handleInputChange(event)}
                        type='text'
                        className='modal-input'
                      />
                      <label htmlFor='name' className='modal-label'>
                        Contact email
                      </label>
                      <input
                        name='contact_email'
                        defaultValue={patient.patient_email}
                        readOnly={true}
                        // onChange={(event) => handleInputChange(event)}
                        type='text'
                        className='modal-input'
                      />
                      <label htmlFor='name' className='modal-label'>
                        Contact Alternate email
                      </label>
                      <input
                        name='contact_alternate_email'
                        onChange={(event) => handleInputChange(event)}
                        type='text'
                        className='modal-input'
                        id='contact_alternate_email'
                      />
                      <label htmlFor='name' className='modal-label'>
                        Job title
                      </label>
                      <input
                        name='job_title'
                        onChange={(event) => handleInputChange(event)}
                        type='text'
                        className='modal-input'
                      />
                      <label htmlFor='name' className='modal-label'>
                        Deployment location
                      </label>
                      <input
                        name='deployment_location'
                        onChange={(event) => handleInputChange(event)}
                        type='text'
                        className='modal-input'
                      />
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <div className='right'>
                    <a
                      onClick={(event) => {
                        event.preventDefault();
                        setShowFormModal(false);
                      }}
                      className='modal-btn cancel w-button close'
                    >
                      Cancel
                    </a>
                    <input
                      type='submit'
                      value='Save'
                      data-wait='Please wait...'
                      className='modal-btn success w-button'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RenderPatientList />
      </form>
      <ShowEditForm />
    </div>
  );
};

export default ServiceRequestForm;

ServiceRequestForm.defaultProps = {
  is_refresh: false,
};
