import fetchClient from "../../config/client";

// APi for client side

const createPatient = async (data) => {
  let response = await fetchClient
    .post("/client/patients", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (res) {
      return res;
    });
  return response;
};
const updatePatient = async (id, data) => {
  let response = await fetchClient
    .put(`/client/patients/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (res) {
      return res;
    });
  return response;
};

const getList = async (q_params) => {
  let patients = await fetchClient
    .get("client/patients.json", { params: q_params })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return [];
    });
  return patients;
};
export const ClientPatientActions = {
  getList,
  createPatient,
  updatePatient,
};

export default ClientPatientActions;
